<template>
	<div
		class="upload-modal-wrapper"
		:class="{ 'upload-modal-wrapper--show': show }"
	>
		<div
			v-if="asset && !uploading_to_virtual_tour_grid && !stage_selected"
			class="upload-modal upload-modal--stage-selection"
		>
			<button
				class="upload-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<div class="upload-modal__content">
				<h2 class="upload-modal__title">
					What do you want to upload?
				</h2>

				<fieldset class="form__radiogroup mb-24">
					<legend class="invisible">Stage</legend>
					<label
						v-for="uploadable_stage in uploadable_stages"
						:key="'stage-' + uploadable_stage"
						:for="value"
						class="form__radio"
					>
						<input
							type="radio"
							:id="uploadable_stage"
							name="project-status"
							:value="uploadable_stage"
							v-model="stage"
						/>
						{{ uploadable_stage }}
					</label>
				</fieldset>

				<div class="upload-modal__buttons mb-16">
					<button
						class="button"
						:disabled="loading"
						@click="closeModal"
					>
						Cancel
					</button>
					<button
						class="button button--fill"
						:class="{ 'button--loading' : loading }"
						:disabled="loading || !stage"
						@click="stage_selected = true"
					>
						Save
					</button>
				</div>
				<p
					v-if="error_message"
					class="error-message"
				>
					{{ error_message }}
				</p>
			</div>
		</div>

		<div
			v-else
			class="upload-modal"
		>
			<button
				class="upload-modal__close"
				aria-label="Close modal"
				@click="closeModal"
			/>
			<Transition name="fade">
				<div
					v-if="upload_format === null"
					class="upload-modal__format-selection"
				>
					<h2 class="upload-modal__format-selection-title">
						Upload Media files
					</h2>
					<p class="upload-modal__format-selection-subtitle">
						Please select the media you wish to upload here
					</p>

					<div
						v-if="uploading_to_virtual_tour_grid"
						class="upload-modal__format-buttons"
					>
						<button
							class="upload-modal__format-button upload-modal__format-button--virtual-tour"
							@click="selectVirtualTourFormat( 'virtual-tour' )"
						>
							Virtual Tour
						</button>

						<button
							class="upload-modal__format-button upload-modal__format-button--stills"
							@click="selectVirtualTourFormat( 'stills' )"
						>
							Stills
						</button>

						<button
							class="upload-modal__format-button upload-modal__format-button--animations"
							@click="selectVirtualTourFormat( 'animations' )"
						>
							Animations
						</button>
					</div>

					<div
						v-else
						class="upload-modal__format-buttons"
					>
						<template
							v-for="media_type in media_types"
							:key="'type-' + media_type.slug"
						>
							<button
								v-if="media_type.base_type !== 'explore' && media_type.slug !== 'virtual-tour'"
								class="upload-modal__format-button"
								:class="'upload-modal__format-button--' + media_type.slug"
								@click="selectFormat( media_type )"
							>
								{{ media_type.title }}
							</button>
						</template>
					</div>
				</div>

				<ImageUploadComponent
					v-else-if="is_image_upload"
					:project="project"
					:asset="asset"
					:stage="stage"
					:format-locked="format_locked"
					@back-to-format-select="upload_format = null"
				/>

				<VideoUploadComponent
					v-else-if="is_video_upload || is_virtual_tour_upload"
					:project="project"
					:asset="asset"
					:stage="stage"
					:format="upload_format"
					:format-locked="format_locked"
					@back-to-format-select="upload_format = null"
				/>

				<VirtualTourImageUploadComponent
					v-else-if="is_virtual_tour_image_upload"
					:project="project"
					:asset="asset"
					@back-to-format-select="upload_format = null"
				/>

				<VirtualTourVideoUploadComponent
					v-else-if="is_virtual_tour_video_upload"
					:project="project"
					:asset="asset"
					@back-to-format-select="upload_format = null"
				/>
			</Transition>
		</div>
	</div>
</template>

<script setup>
import ImageUploadComponent from './ImageUploadComponent.vue';
import VideoUploadComponent from './VideoUploadComponent.vue';
import VirtualTourVideoUploadComponent from './VirtualTourVideoUploadComponent.vue';
import VirtualTourImageUploadComponent from './VirtualTourImageUploadComponent.vue';
import {
	// UPLOAD_FORMAT_DRAFT,
	UPLOAD_FORMAT_IMAGE,
	UPLOAD_FORMAT_VIDEO,
	UPLOAD_FORMAT_VIRTUAL_TOUR,
	UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE,
	UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO,
} from '../../constants';

import { useDataStore } from '../../stores/data';
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';

const data_store = useDataStore();

const props = defineProps( {
	show: { required: true, type: Boolean },
	project: { required: true, type: Object },
	asset: { required: false, type: Object }
} );

const emit = defineEmits( ['close-modal'] );

const route = useRoute();

const show = computed( () => props.show );
const project = computed( () => props.project );
const asset = computed( () => props.asset );

const { media_types } = storeToRefs( data_store );

const uploading_to_virtual_tour_grid = computed( () => asset.value && route.query && route.query.virtual_tour );

const uploadable_stages = computed( () => {
	if ( !asset.value || asset.value.final_revision.length ) {
		return null;
	}
	if ( !asset.value.asset_revisions.length ) {
		return ['A1'];
	}
	const latest_stage = asset.value.asset_revisions.at( -1 );

	if ( latest_stage.revision_number[0].toUpperCase() == 'A' ) {
		return [
			'A' + ( parseInt( latest_stage.revision_number.slice( 1 ) ) + 1 ),
			'B1'
		]
	}
	return [ 'B' + ( parseInt( latest_stage.revision_number.slice( 1 ) ) + 1 ) ];
} );

const upload_format = ref( null );
const format_locked = ref( false );
const stage = ref( null );
const stage_selected = ref( null );

// const is_draft_upload = computed ( () => {
// 	return upload_format.value === UPLOAD_FORMAT_DRAFT
// } );
const is_virtual_tour_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_TOUR;
} );
const is_virtual_tour_image_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE;
} );
const is_virtual_tour_video_upload = computed( () => {
	return upload_format.value === UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO;
} );
const is_image_upload = computed ( () => {
	return upload_format.value === UPLOAD_FORMAT_IMAGE;
} );
const is_video_upload = computed ( () => {
	return upload_format.value === UPLOAD_FORMAT_VIDEO;
} );

function selectFormat( media_type ) {
	const base_type = media_type.base_type;
	switch ( base_type ) {
		case 'image':
			upload_format.value = UPLOAD_FORMAT_IMAGE;
			break;
		case 'video':
			upload_format.value = UPLOAD_FORMAT_VIDEO;
			break;
		default:
			upload_format.value = null;
	}
}

function selectVirtualTourFormat( type ) {
	switch ( type ) {
		case 'virtual-tour':
			upload_format.value = UPLOAD_FORMAT_VIRTUAL_TOUR;
			break;
		case 'stills':
			upload_format.value = UPLOAD_FORMAT_VIRTUAL_TOUR_IMAGE;
			break;
		case 'animations':
			upload_format.value = UPLOAD_FORMAT_VIRTUAL_TOUR_VIDEO;
			break;
		default:
			upload_format.value = null;
	}
	if ( !upload_format.value ) {
		return;
	}

}

function closeModal() {
	emit( 'close-modal' );
	stage.value = null;
	stage_selected.value = false;

	if ( !format_locked.value ) {
		upload_format.value = null;
	}
}

onMounted( () => {
	if ( asset.value && asset.value.media_type.length && !uploading_to_virtual_tour_grid.value ) {
		selectFormat( asset.value.media_type[0] );
		format_locked.value = true;
	}
} );
</script>